<template>
    <a-form v-slot="{ invalid }" :submit="save">
        <v-navigation-drawer
            v-model="isOpen"
            class="clientcabin clientcabin-setup pa-8 secondary--text"
            app
            right
            temporary
            disable-route-watcher
            :width="width"
        >
            <template #prepend>
                <v-container>
                    <v-row>
                        <v-col class="pa-0">
                            <div class="text-h6 font-weight-bold px-0">
                                Setup Your Invisible Sales Machine
                            </div>
                        </v-col>
                        <v-col cols="auto" align="center" class="pa-0 mr-n2">
                            <v-btn icon @click="close">
                                <v-icon>close</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="px-0 mb-2 pt-2">
                            Settings of your sales pages
                        </v-col>
                    </v-row>
                </v-container>
                <v-divider class="mt-n1 mb-6" />
                <v-tabs v-model="tab" color="secondary">
                    <v-tab class="text-capitalize font-weight-bold">
                        General Settings
                    </v-tab>
                    <v-tab
                        class="text-capitalize font-weight-bold"
                        @click="goTo('/info')"
                    >
                        Info
                    </v-tab>
                    <v-tab
                        class="text-capitalize font-weight-bold"
                        @click="goTo('/proposal')"
                    >
                        Sales
                    </v-tab>
                    <v-tab
                        v-show="isVIPReseller"
                        class="text-capitalize font-weight-bold"
                        @click="goTo('/proposal')"
                    >
                        Leads from Ads
                    </v-tab>
                    <v-tab
                        class="text-capitalize font-weight-bold"
                        @click="goTo('/book')"
                    >
                        Book
                    </v-tab>
                    <v-tooltip bottom :close-delay="copied ? copyTimeout : 0">
                        <template #activator="{ on, attrs }">
                            <div class="d-flex align-center pl-2">
                                <v-btn
                                    v-clipboard:success="copy"
                                    v-clipboard:copy="pagePath"
                                    :disabled="!tab"
                                    icon
                                    right
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon small>copy</v-icon>
                                </v-btn>
                            </div>
                        </template>
                        <span>
                            {{ copyTooltip }}
                        </span>
                    </v-tooltip>
                </v-tabs>
            </template>

            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <p class="pt-4">
                        These are settings that we apply to every page.
                    </p>
                    <general-settings
                        class="pt-2"
                        :options="data"
                        @input="updateOptions"
                    />
                </v-tab-item>
                <v-tab-item>
                    <p class="pt-4">
                        This page is for the initial application. This is
                        followed by a series of questions used to gather
                        information from the client.
                    </p>
                    <info-page
                        class="pt-2"
                        :options="data"
                        @input="updateOptions"
                    />
                </v-tab-item>
                <v-tab-item>
                    <p class="pt-4">
                        This page offers the client to checkout and buy one of
                        the products. Please check your
                        <a
                            href="/app/profile/whitelabel"
                            target="_blank"
                            class="d-inline-block"
                        >
                            user panel
                        </a>
                        to set prices and currency for the Media Coverage
                        Campaign (Price to Sell Amp Campaigns).
                    </p>
                    <sales-page
                        class="pt-2"
                        :options="data"
                        @input="updateOptions"
                    />
                </v-tab-item>
                <v-tab-item v-show="isVIPReseller">
                    <leads-from-ads-page
                        class="pt-2"
                        :options="data"
                        @input="updateOptions"
                    />
                </v-tab-item>
                <v-tab-item>
                    <p class="pt-4">
                        Enable clients to easily schedule a meeting with you
                        through this page.
                    </p>
                    <book-page
                        class="pt-2"
                        :options="data"
                        :reseller-booking-url="reseller_booking_url"
                        @input="updateBookingOptions"
                    />
                </v-tab-item>
            </v-tabs-items>
            <template #append>
                <v-container>
                    <v-row>
                        <v-col>
                            <v-btn
                                color="primary"
                                :block="$vuetify.breakpoint.smAndDown"
                                :disabled="invalid"
                                type="submit"
                            >
                                Save
                            </v-btn>
                            <v-btn
                                :block="$vuetify.breakpoint.smAndDown"
                                class="ml-md-4"
                                text
                                @click="cancel"
                            >
                                Cancel
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </v-navigation-drawer>
    </a-form>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';
import { InjectReactive, Watch } from '@/utils/decorators';

import { AForm } from '@/components/AForm';

import { DefaultOptions } from '@/components/ClientCabin/Setup';

import GeneralSettings from '@/components/ClientCabin/Setup/sections/GeneralSettings.vue';
import InfoPage from '@/components/ClientCabin/Setup/sections/InfoPage.vue';
import SalesPage from '@/components/ClientCabin/Setup/sections/SalesPage.vue';
import BookPage from '@/components/ClientCabin/Setup/sections/BookPage.vue';
import LeadsFromAdsPage from '@/components/ClientCabin/Setup/sections/LeadsFromAdsPage.vue';

import type { ClientCabin, ClientCabinOptions } from '@/types/ClientCabin';

@Component({
    name: 'Setup',
    components: {
        AForm,
        GeneralSettings,
        InfoPage,
        SalesPage,
        BookPage,
        LeadsFromAdsPage
    },
    computed: {
        ...mapGetters('user', ['isVIPReseller'])
    }
})
export default class Setup extends Vue {
    isVIPReseller!: boolean;

    id = 'clientcabin-setup-panel';

    data: ClientCabinOptions = DefaultOptions;

    reseller_booking_url = '';

    copied = false;

    copyTimeout = 2000;

    tab = 0;

    @InjectReactive({
        from: 'setupEndpoint',
        default() {
            return '/';
        }
    })
    setupEndpoint!: string;

    @InjectReactive({
        from: 'options',
        default() {
            return false;
        }
    })
    options!: Partial<ClientCabin> | null;

    @Watch('options')
    onOptionsUpdate() {
        this.readOptions();
    }

    @InjectReactive({
        from: 'defaults'
    })
    defaults!: ClientCabinOptions;

    @InjectReactive({
        from: 'setMachineOptions',
        default() {
            return () => {};
        }
    })
    setMachineOptions!: (
        options: ClientCabinOptions,
        reseller_booking_url: string
    ) => void;

    get isOpen() {
        return this.$store?.getters['modal/open'](this.id);
    }

    set isOpen(isOpen: boolean) {
        if (!isOpen) {
            this.$store.dispatch('modal/close', this.id);
        }
    }

    get width() {
        return this.$vuetify.breakpoint.smAndDown
            ? '100%'
            : this.$vuetify.breakpoint.xl
              ? '30%'
              : this.$vuetify.breakpoint.md
                ? '50%'
                : '40%';
    }

    get pagePath() {
        return (
            window.location.origin +
            this.$router.options.base +
            this.$route.path
        );
    }

    get copyTooltip() {
        return this.copied ? 'Link copied' : 'Click to copy link of this page';
    }

    mounted() {
        this.readOptions();
    }

    save() {
        this.setOptions();

        this.saveOptions();

        this.close();
    }

    cancel() {
        this.close();

        this.$nextTick(() => {
            this.readOptions();
        });
    }

    close() {
        this.$store.dispatch('modal/close', this.id);
    }

    readOptions() {
        const { options, reseller_booking_url } = this.options || {};

        this.reseller_booking_url = reseller_booking_url || '';

        this.data.custom_headline_short = options?.custom_headline_short || '';

        this.data.custom_headline_long = options?.custom_headline_long || '';

        this.data.video_url = options?.video_url || '';

        this.data.sales_video_url = options?.sales_video_url || '';

        this.data.vip_buy_button_url = options?.vip_buy_button_url || '';

        if (options) {
            this.readFooter(options);

            this.readAnalytics(options);

            this.readCrms(options);

            this.readSchedulers(options);
        }
    }

    readFooter(options: ClientCabinOptions) {
        this.data.footer = {
            links: []
        };

        if (options.footer.links) {
            this.data.footer = {
                links: [...options.footer.links]
            };
        }

        this.convertOldFooterLink(
            options.footer.link_text,
            options.footer.link_url
        );
    }

    readAnalytics(options: ClientCabinOptions) {
        this.data.analytics = {
            google_analytics: options.analytics.google_analytics,
            fb_pixel: options.analytics.fb_pixel,
            segment_io: options.analytics.segment_io
        };
    }

    readCrms(options: ClientCabinOptions) {
        this.data.crm = {
            google: options.crm?.google || '',
            hubspot: options.crm?.hubspot || '',
            getresponse: options.crm?.getresponse || ''
        };

        this.data.default_crm = options.default_crm || 'google';
    }

    readSchedulers(options: ClientCabinOptions) {
        this.data.scheduler = {
            calendly: options.scheduler?.calendly || '',
            acuity: options.scheduler?.acuity || '',
            hubspot: options.scheduler?.hubspot || '',
            googlecalendar: options.scheduler?.googlecalendar || ''
        };

        this.data.default_scheduler = options.default_scheduler || '';
    }

    setOptions() {
        this.setMachineOptions(this.data, this.reseller_booking_url);
    }

    updateOptions(data: ClientCabinOptions) {
        this.data = { ...this.data, ...data };
    }

    updateBookingOptions({
        options,
        reseller_booking_url
    }: {
        options: ClientCabinOptions;
        reseller_booking_url: string;
    }) {
        this.data = { ...this.data, ...options };
        this.reseller_booking_url = reseller_booking_url;
    }

    saveOptions() {
        this.$http.post(this.setupEndpoint, {
            reseller_booking_url: this.reseller_booking_url,
            reseller_invisible_machine_options: this.data
        });
    }

    copy() {
        this.copied = true;

        setTimeout(() => {
            this.copied = false;
        }, this.copyTimeout);
    }

    convertOldFooterLink(text = '', url = '') {
        if (!this.data.footer.links.length && (text || url)) {
            this.data.footer.links.push({
                label: text,
                url: url
            });
        }
    }

    goTo(path: string) {
        if (this.$route.path !== path) {
            this.$router.push(path);
        }
    }
}
</script>

<style lang="scss" scoped>
.clientcabin-setup::v-deep {
    .v-navigation-drawer__prepend {
        overflow: visible;
    }

    .v-tabs {
        width: calc(100% + 50px);

        .v-tab {
            border-bottom: 2px solid transparent;
            padding-left: 12px;
            padding-right: 12px;
            min-width: 50px;

            &[aria-selected='false'] {
                border-bottom: 2px solid #ccc;
            }
        }

        .v-slide-group__prev,
        .v-slide-group__next {
            min-width: 30px;
            flex-basis: 30px;

            .v-icon__component {
                font-size: 16px;
                width: 16px;
                height: 16px;
            }
        }

        .v-slide-group--has-affixes {
            margin-left: -30px;
        }
    }

    .v-expansion-panel[aria-expanded='false'] {
        border-top: 1px solid $mercury-solid;
    }

    .v-expansion-panel-header__icon > .v-icon {
        font-size: 16px;
        width: 16px;
        height: 16px;

        .v-icon__component {
            width: 16px;
            height: 16px;
        }
    }

    .a-textarea.code textarea {
        font-family: monospace;
        font-size: 85%;
        line-height: 1.3em;
    }

    .v-btn--active.semi-active {
        color: grey;
    }
}
</style>
