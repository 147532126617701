<template>
    <v-expansion-panel>
        <!-- We don't have any expandable panels here, just single input field -->
        <div class="black--text px-6">
            <p class="pt-4">
                Add custom Checkout button URL for VIP Agency Sales page
            </p>
            <a-text-input
                v-model="data.vip_buy_button_url"
                label="Checkout URL"
                rules="url|max:1024"
                autocomplete="off"
            />
        </div>
    </v-expansion-panel>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { Watch } from '@/utils/decorators';

import { ATextInput } from '@/components/AForm/Inputs/ATextInput';
import { DefaultOptions } from '@/components/ClientCabin/Setup';

import type { ClientCabinOptions } from '@/types/ClientCabin';

const LeadsFromAdsPageProps = Vue.extend({
    name: 'LeadsFromAdsPage',
    props: {
        options: {
            type: Object as PropType<ClientCabinOptions>,
            default() {
                return DefaultOptions;
            }
        }
    }
});

@Component({
    components: {
        ATextInput
    }
})
export default class LeadsFromAdsPage extends LeadsFromAdsPageProps {
    data = this.options;

    @Watch('data', { deep: true })
    onChange() {
        this.$emit('input', this.data);
    }
}
</script>
