import type { Country } from './Country';
import type { MediaResource } from './MediaResource';
import type { User } from './User';

export type Company = {
    id: number;
    user_id: number;
    user?: User;
    country_id: number;
    name: string;
    address1: string;
    address2: string | null;
    city: string;
    state: string;
    postal_code: string;
    contact_name: string;
    email: string;
    display_email: boolean;
    phone: string;
    display_phone: boolean;
    website: string | null;
    twitter_handle: string | null;
    facebook_page: string | null;
    gather_leads: boolean;
    year_founded: number | null;
    business_niche: string | null;
    speciality: string | null;
    created: number | null;
    modified: number | null;
    instagram: string | null;
    enable_feeds: boolean;
    iw_auth_authorised_position: string | null;
    iw_auth_company_registration_number: string | null;
    iw_auth_duns_number: string | null;
    iw_auth_entity_type: string | null;
    iw_auth_registration_certificate_provided: boolean | null;
    iw_auth_authorisation_letterhead_provided: boolean | null;
    iw_auth_media_contact_authorised_in_authorisation: boolean | null;
    iw_auth_person_in_charge_authorised_in_authorisation: boolean | null;
    iw_auth_authorised_name: string | null;
    media_resources: MediaResource[];
    interwire_business_registration_certificate: MediaResource | null;
    interwire_letter_of_authorization: MediaResource | null;
    country: Country;
    full_address: string;
    country_name: string;

    duns_number: string | null;
    registration_number: string | null;
    entity_type: number;
    entity_type_other: string | null;

    media_contact_name: string | null;
    media_contact_phone: string | null;
    media_contact_email: string | null;
    media_contact_designation: string | null;

    representative_contact_name: string | null;
    representative_contact_phone: string | null;
    representative_contact_email: string | null;
    representative_contact_designation: string | null;

    authorized_contact_name: string | null;
    authorized_contact_phone: string | null;
    authorized_contact_designation: string | null;

    company_signatory: MediaResource | null;

    agency_letter_of_authorization: MediaResource | null;
    company_oauths: CompanyAccountConnection[];
    about: string | null;
};

export type CompanyControl = {
    show: boolean;
    loading: boolean;
    isCreating: boolean;
    selectedCompany: Company | null;
};

export enum DistributionSites {
    Twitter = 114,
    YouTube = 115,
    Vimeo = 116,
    LinkedIn = 119,
    TikTok = 120,
    SlideShare = 121
}

export const AppConnectionCookie = 'connecting-app';

export interface CompanyAccountConnectionCookie {
    site: DistributionSites;
}

export type CompanyAccountConnection = {
    id?: number;
    company_id: number;
    distribution_site_id: DistributionSites;
    display_name: string | null;
    token: string;
    username: string;
    password: string;
};
