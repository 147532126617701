import axios from 'axios';
import { extend } from 'vee-validate';
import { stripHtml } from '@/utils/helpers';

const ENDPOINT = '/press_releases/first_person_check';

let validator: ReturnType<typeof setTimeout> | void = void 0;

async function validateContent(content: string) {
    return axios
        .post(
            ENDPOINT,
            { content },
            {
                // avoid showing loading indicator
                headers: {
                    silent: true
                }
            }
        )
        .then(({ data }) => {
            return data?.data?.success || false;
        })
        .catch(() => false);
}

extend('nonFirstPerson', {
    validate(value: string) {
        if (!stripHtml(value)) {
            return true;
        }

        return new Promise(resolve => {
            if (validator) {
                clearTimeout(validator);
                validator = void 0;
            }

            validator = setTimeout(() => {
                resolve(validateContent(value));
            }, 1000);
        });
    }
});
