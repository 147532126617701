import { MediaResource } from '@/types/MediaResource';

export default function areMediaResourcesEqual(
    a: MediaResource[] = [],
    b: MediaResource[] = []
): boolean {
    if (a.length === b.length) {
        a.forEach((v, i) => {
            const as = JSON.stringify(v);
            const bs = JSON.stringify(b[i]);

            if (as !== bs) {
                return false;
            }
        });
    } else {
        return false;
    }

    return true;
}
