import DOMPurify from 'dompurify';

type CalendlyParameters = {
    dataUrl: string | null;
    style: string | null;
};

export const extractor = (code: string) => {
    const extracted: CalendlyParameters = {
        dataUrl: null,
        style: null
    };

    const sanitized = DOMPurify.sanitize(code, {
        ADD_TAGS: ['div'],
        ADD_ATTR: ['data-url', 'style']
    });

    if (sanitized) {
        const parser = new DOMParser();

        const form = parser.parseFromString(sanitized, 'text/html');
        const div = form.querySelector('[data-url]');

        if (div) {
            const dataUrl = div.getAttribute('data-url') || '';

            const [, tail] = dataUrl.split('calendly.com/');

            if (tail) {
                // avoid hacky links
                extracted.dataUrl = `https://calendly.com/${tail}`;
            }

            const style = div.getAttribute('style') || '';

            if (style) {
                extracted.style = style;
            }
        }
    }

    return extracted;
};

export const validator = async (code: string) => {
    if (!code) {
        return false;
    }

    const { dataUrl } = extractor(code);

    return Boolean(dataUrl);
};

export default { validator, extractor };
