<template>
    <v-expansion-panels accordion focusable flat tile>
        <v-expansion-panel>
            <v-expansion-panel-header class="font-weight-bold">
                Headlines
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <p class="pt-4">
                    Define custom headline for
                    <b>Sales</b>
                    page
                </p>
                <a-text-input
                    v-model="data.custom_headline_long"
                    :placeholder="defaults.custom_headline_long"
                    rules="max:1024"
                    label="Sales page Headline"
                    textarea
                    auto-grow
                />
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
            <v-expansion-panel-header class="font-weight-bold">
                Video
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <p class="pt-4">
                    To embed a video on your
                    <b>Sales</b>
                    page, add the video URL. If no custom video is added, our
                    top-performing sales video will be displayed by default to
                    enhance engagement.
                </p>

                <a-text-input
                    v-model="data.sales_video_url"
                    :placeholder="defaults.sales_video_url"
                    label="Video URL"
                    rules="url|max:255"
                    autocomplete="off"
                />
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { InjectReactive, Watch } from '@/utils/decorators';

import { ATextInput } from '@/components/AForm/Inputs/ATextInput';
import { DefaultOptions } from '@/components/ClientCabin/Setup';

import type { ClientCabinOptions } from '@/types/ClientCabin';

const SalesPageProps = Vue.extend({
    name: 'SalesPage',
    props: {
        options: {
            type: Object as PropType<ClientCabinOptions>,
            default() {
                return DefaultOptions;
            }
        }
    }
});

@Component({
    components: {
        ATextInput
    }
})
export default class SalesPage extends SalesPageProps {
    data = this.options;

    @InjectReactive({
        from: 'defaults'
    })
    defaults!: ClientCabinOptions;

    @Watch('data', { deep: true })
    onChange() {
        this.$emit('input', this.data);
    }
}
</script>
