<template>
    <div class="pa-1">
        <p class="pt-4">
            Add custom Booking Scheduler on
            <b>Book</b>
            page. You can pick from one of the providers below.
        </p>
        <v-expansion-panels>
            <v-expansion-panel key="custom_url">
                <v-expansion-panel-header class="py-0 pl-2">
                    <v-row no-gutters>
                        <v-col cols="auto" class="pr-4">
                            <v-tooltip v-if="isDefaultProvider('')" bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon color="blue" small>
                                            fa-square-check
                                        </v-icon>
                                    </v-btn>
                                </template>
                                This Provider is default
                            </v-tooltip>
                            <v-tooltip v-else bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click.stop.native="
                                            setDefaultProvider('')
                                        "
                                    >
                                        <v-icon small>
                                            far fa-square-check
                                        </v-icon>
                                    </v-btn>
                                </template>
                                Set this Provider as default
                            </v-tooltip>
                        </v-col>
                        <v-col align-self="center">Custom link</v-col>
                        <v-col
                            v-if="isDefaultProvider('')"
                            cols="auto"
                            align-self="center"
                            class="caption px-4 font-weight-bold blue--text"
                        >
                            Your Booking Link
                        </v-col>
                    </v-row>
                    <template #actions>
                        <v-icon small>$expand</v-icon>
                    </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <p class="pt-4">
                        This will be the a link to book a meeting with you
                    </p>
                    <a-text-input
                        v-model="reseller_booking_url"
                        label="Book A Meeting Link (optional)"
                        rules="url"
                        placeholder="http(s)://..."
                        persistent-hint
                        autocomplete="off"
                    />
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-for="provider in providers" :key="provider.id">
                <v-expansion-panel-header class="py-0 pl-2">
                    <v-row no-gutters>
                        <v-col cols="auto" class="pr-4">
                            <v-tooltip
                                v-if="isDefaultProvider(provider)"
                                bottom
                            >
                                <template #activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon color="blue" small>
                                            fa-square-check
                                        </v-icon>
                                    </v-btn>
                                </template>
                                This Provider is default
                            </v-tooltip>
                            <v-tooltip v-else bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click.stop.native="
                                            setDefaultProvider(provider)
                                        "
                                    >
                                        <v-icon small>
                                            far fa-square-check
                                        </v-icon>
                                    </v-btn>
                                </template>
                                Set this Provider as default
                            </v-tooltip>
                        </v-col>
                        <v-col align-self="center">
                            {{ provider.name }}
                        </v-col>
                        <v-col
                            v-if="isDefaultProvider(provider)"
                            cols="auto"
                            align-self="center"
                            class="caption px-4 font-weight-bold blue--text"
                        >
                            Your Booking Form
                        </v-col>
                    </v-row>
                    <template #actions>
                        <v-icon small>$expand</v-icon>
                    </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <p v-if="provider.helpLink" class="pt-4">
                        Learn how to create
                        {{ provider.name }} form
                        <a :href="provider.helpLink" target="_blank">here</a>
                    </p>
                    <!-- eslint-disable vue/no-v-html -->
                    <p
                        v-if="provider.details"
                        class="text-caption"
                        v-html="provider.details"
                    ></p>
                    <!-- eslint-enable -->
                    <a-text-input
                        v-model="data.scheduler[provider.id]"
                        :label="provider.name + ' code'"
                        :spellcheck="false"
                        :rules="initSchedulerValidator(provider)"
                        autocomplete="off"
                        textarea
                        rows="10"
                        class="code"
                    />
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { InjectReactive, Watch } from '@/utils/decorators';

import { ATextInput } from '@/components/AForm/Inputs/ATextInput';
import { DefaultOptions } from '@/components/ClientCabin/Setup';
import {
    setValidator,
    unsetValidator
} from '@/plugins/vee-validate/rules/runtime';

import type { ClientCabinOptions } from '@/types/ClientCabin';
import schedulerProviders, {
    type SchedulerProvider
} from '@/components/ClientCabin/Setup/SchedulerProviders';

let validators: string[] = [];

const BookPageProps = Vue.extend({
    name: 'BookPage',
    props: {
        options: {
            type: Object as PropType<ClientCabinOptions>,
            default() {
                return DefaultOptions;
            }
        },
        resellerBookingUrl: {
            type: String,
            default() {
                return '';
            }
        }
    }
});

@Component({
    components: {
        ATextInput
    }
})
export default class BookPage extends BookPageProps {
    data = this.options;

    reseller_booking_url = this.resellerBookingUrl;

    providers = schedulerProviders;

    @InjectReactive({
        from: 'defaults'
    })
    defaults!: ClientCabinOptions;

    @Watch('data', { deep: true })
    @Watch('reseller_booking_url', { deep: true })
    onChange() {
        this.$emit('input', {
            options: this.data,
            reseller_booking_url: this.reseller_booking_url
        });
    }

    isDefaultProvider(provider: SchedulerProvider | '') {
        return this.data.default_scheduler === (provider ? provider.id : '');
    }

    setDefaultProvider(provider: SchedulerProvider | '') {
        this.data.default_scheduler = provider ? provider.id : '';
    }

    initSchedulerValidator(provider: SchedulerProvider) {
        setValidator(provider.id, provider.validator);

        validators.push(provider.id);

        return `scheduler:${provider.id}`;
    }

    beforeDestroy() {
        validators.forEach(id => {
            unsetValidator(id);
        });

        validators = [];
    }
}
</script>
