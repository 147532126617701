import Vue from 'vue';
import Component from 'vue-class-component';
import { stripEmptyLinks, findUrls, debounce } from '@/utils/helpers';

@Component
export default class ValidateLinksReachability extends Vue {
    allLinksReachable = true;
    containsLinks = false;

    debouncedProcessLinks = debounce(this.processLinks, 1000);

    async validateLinksReachability(text: string, isHtml = true) {
        return this.debouncedProcessLinks(text, isHtml);
    }

    async processLinks(text: string, isHtml = true) {
        const urls = findUrls(stripEmptyLinks(text), isHtml);

        if (urls.length) {
            this.containsLinks = true;
            return this.validateLinks(urls);
        } else {
            this.containsLinks = false;
            this.allLinksReachable = true;
        }
    }

    async validateLinks(links: string[]) {
        try {
            const { data } = await this.$http.post(
                '/press_releases/validate_links',
                {
                    content: links
                },
                {
                    // avoid showing loading indicator
                    headers: {
                        silent: true
                    }
                }
            );

            this.allLinksReachable = data?.data.success || false;
        } catch {
            this.allLinksReachable = false;
        }
    }
}
