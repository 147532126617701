import { copy } from '@/utils/helpers';

import Vue from 'vue';
import Component from 'vue-class-component';

import type { MediaResource } from '@/types/MediaResource';
import type { VideoPr, VideoTemplate } from '@/types/Video';

@Component
export default class VideoTemplateActions extends Vue {
    copyVideoDetails(from: Partial<VideoPr>, to: Partial<VideoPr>) {
        to.title = from.title;
        to.description = from.description;

        to.video_voice_id = from.video_voice_id;
        to.video_audio_clip_id = from.video_audio_clip_id;

        to.tags_array = from.tags_array;
        to.add_ident = from.add_ident;

        this.copyVideoTemplateDetails(from.video_template, to.video_template);
    }

    copyVideoTemplateDetails(
        from?: Partial<VideoTemplate>,
        to?: Partial<VideoTemplate>
    ) {
        if (from && to) {
            const source = copy(from);

            to.video_boxes = to.video_boxes?.map(box => {
                // copy captions
                if (box.type === 'video_text_box' && box.video_text_box) {
                    box.video_text_box.video_caption.text = this.findCaption(
                        source,
                        box.video_text_box.video_caption.text
                    );
                }
                // copy media files
                if (
                    box.type === 'video_media_box' &&
                    box.video_media_box &&
                    !box.video_media_box.is_logo_box
                ) {
                    box.video_media_box.media_resource = this.findMediaFile(
                        source,
                        box.video_media_box.media_resource
                    );
                }

                return box;
            });
        }
    }

    findCaption(source: Partial<VideoTemplate>, fallback: string) {
        if (source.video_boxes) {
            const index = source.video_boxes.findIndex(box => {
                return (
                    box.type === 'video_text_box' &&
                    box.video_text_box &&
                    box.video_text_box.video_caption.text
                );
            });

            if (~index) {
                const caption =
                    source.video_boxes[index]?.video_text_box?.video_caption
                        ?.text;

                if (caption) {
                    source.video_boxes?.splice(index, 1);

                    return caption;
                }
            }
        }

        return fallback;
    }

    findMediaFile(source: Partial<VideoTemplate>, target: MediaResource) {
        if (source.video_boxes && !target.media_file?.is_system_media) {
            const index = source.video_boxes.findIndex(box => {
                return (
                    box.type === 'video_media_box' &&
                    !box.video_media_box?.is_logo_box &&
                    box.video_media_box?.media_resource.media_file &&
                    !box.video_media_box?.media_resource.media_file
                        .is_system_media
                );
            });

            if (~index) {
                const resource =
                    source.video_boxes[index]?.video_media_box?.media_resource;

                if (resource) {
                    target.media_file_id = resource.media_file_id;
                    target.media_file = resource.media_file;

                    if (target.crop_height) {
                        // reset crop
                        target.crop_height = null;
                        target.crop_width = null;
                        target.crop_x = null;
                        target.crop_y = null;
                    }

                    source.video_boxes?.splice(index, 1);
                }
            }
        }

        return target;
    }
}
