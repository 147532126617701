import { CrmType } from '@/types/ClientCabin';

import HubSpot from '@/components/ClientCabin/CRM/HubSpot';
import GetResponse from '@/components/ClientCabin/CRM/GetResponse';
import GoogleForm from '@/components/ClientCabin/CRM/GoogleForm';

export type ConnectFormProvider = {
    id: CrmType;
    name: string;
    details?: string;
    helpLink: string;
    validator: (code: string) => Promise<boolean>;
};

const connectFormProviders: ConnectFormProvider[] = [
    {
        id: 'getresponse',
        name: 'GetResponse',
        details:
            'We need both <b>WebConnect</b> code and <b>Embedded form code</b> you get from GetResponse. You can copy and paste one after another.',
        helpLink:
            'https://www.getresponse.com/help/how-to-install-new-forms-and-popups-on-a-landing-page.html',
        validator: GetResponse.validator
    },
    {
        id: 'google',
        name: 'Google Forms',
        details: 'Only <b>one-page</b> Google Forms are supported',
        helpLink: 'https://support.google.com/a/users/answer/9299716',
        validator: GoogleForm.validator
    },
    {
        id: 'hubspot',
        name: 'HubSpot',
        helpLink:
            'https://knowledge.hubspot.com/forms/how-can-i-share-a-hubspot-form-if-im-using-an-external-site',
        validator: HubSpot.validator
    }
];

export default connectFormProviders;
