<template>
    <v-expansion-panels accordion focusable flat tile>
        <v-expansion-panel>
            <v-expansion-panel-header class="font-weight-bold">
                Headlines
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <p class="pt-4">
                    Define custom headline for
                    <b>Info</b>
                    page
                </p>
                <a-text-input
                    v-model="data.custom_headline_short"
                    :placeholder="defaults.custom_headline_short"
                    rules="max:1024"
                    label="Info page Headline"
                    textarea
                    auto-grow
                />
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
            <v-expansion-panel-header class="font-weight-bold">
                Video
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <p class="pt-4">
                    To display a video on your
                    <b>Info</b>
                    page, simply add the video URL. Once added, the video will
                    automatically appear. By default, no video will be shown
                    unless you manually input a URL.
                </p>

                <a-text-input
                    v-model="data.video_url"
                    :placeholder="defaults.video_url"
                    label="Video URL"
                    rules="url|max:255"
                    autocomplete="off"
                />
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
            <v-expansion-panel-header class="font-weight-bold">
                Connect form
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <p class="pt-4">
                    Add custom Form on
                    <b>Info</b>
                    page. You can pick from one of the providers below.
                </p>
                <v-expansion-panels>
                    <v-expansion-panel
                        v-for="provider in providers"
                        :key="provider.id"
                    >
                        <v-expansion-panel-header class="py-0 pl-2">
                            <v-row no-gutters>
                                <v-col cols="auto" class="pr-4">
                                    <v-tooltip
                                        v-if="isDefaultProvider(provider)"
                                        bottom
                                    >
                                        <template #activator="{ on, attrs }">
                                            <v-btn
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon color="blue" small>
                                                    fa-square-check
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        This Provider is default
                                    </v-tooltip>
                                    <v-tooltip v-else bottom>
                                        <template #activator="{ on, attrs }">
                                            <v-btn
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                                @click.stop.native="
                                                    setDefaultProvider(provider)
                                                "
                                            >
                                                <v-icon small>
                                                    far fa-square-check
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        Set this Provider as default
                                    </v-tooltip>
                                </v-col>
                                <v-col align-self="center">
                                    {{ provider.name }}
                                </v-col>
                                <v-col
                                    v-if="isDefaultProvider(provider)"
                                    cols="auto"
                                    align-self="center"
                                    class="caption px-4 font-weight-bold blue--text"
                                >
                                    Your Connect Form
                                </v-col>
                            </v-row>
                            <template #actions>
                                <v-icon small>$expand</v-icon>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="black--text">
                            <p class="pt-4">
                                Learn how to create
                                {{ provider.name }} form
                                <a :href="provider.helpLink" target="_blank">
                                    here
                                </a>
                            </p>
                            <!-- eslint-disable vue/no-v-html -->
                            <p
                                v-if="provider.details"
                                class="text-caption"
                                v-html="provider.details"
                            ></p>
                            <!-- eslint-enable -->
                            <a-text-input
                                v-model="data.crm[provider.id]"
                                :label="provider.name + ' code'"
                                :spellcheck="false"
                                :rules="initConnectFormValidator(provider)"
                                autocomplete="off"
                                textarea
                                rows="10"
                                class="code"
                            />
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { InjectReactive, Watch } from '@/utils/decorators';

import { ATextInput } from '@/components/AForm/Inputs/ATextInput';
import { DefaultOptions } from '@/components/ClientCabin/Setup';

import type { ClientCabinOptions } from '@/types/ClientCabin';
import connectFormProviders, {
    type ConnectFormProvider
} from '@/components/ClientCabin/Setup/ConnectFormProviders';
import {
    setValidator,
    unsetValidator
} from '@/plugins/vee-validate/rules/runtime';

let validators: string[] = [];

const InfoPageProps = Vue.extend({
    name: 'InfoPage',
    props: {
        options: {
            type: Object as PropType<ClientCabinOptions>,
            default() {
                return DefaultOptions;
            }
        }
    }
});

@Component({
    components: {
        ATextInput
    }
})
export default class InfoPage extends InfoPageProps {
    data = this.options;

    providers = connectFormProviders;

    @InjectReactive({
        from: 'defaults'
    })
    defaults!: ClientCabinOptions;

    @Watch('data', { deep: true })
    onChange() {
        this.$emit('input', this.data);
    }

    isDefaultProvider(provider: ConnectFormProvider) {
        return this.data.default_crm === provider.id;
    }

    setDefaultProvider(provider: ConnectFormProvider) {
        this.data.default_crm = provider.id;
    }

    initConnectFormValidator(provider: ConnectFormProvider) {
        setValidator(provider.id, provider.validator);

        validators.push(provider.id);

        return `crm:${provider.id}`;
    }

    beforeDestroy() {
        validators.forEach(id => {
            unsetValidator(id);
        });

        validators = [];
    }
}
</script>
