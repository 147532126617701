import Vue, { PropType, VueConstructor } from 'vue';
import Component, { mixins } from 'vue-class-component';
import { Watch } from '@/utils/decorators';

import {
    setValidator,
    unsetValidator
} from '@/plugins/vee-validate/rules/runtime';
import { ValidationProvider } from 'vee-validate';

import {
    MediaBoxActions,
    MediaModuleSlideCollector,
    MediaModuleValidate,
    ResourceFile
} from '@/mixins';

import type { MediaFile } from '@/types/MediaFile';
import type { VideoBox, VideoTemplate } from '@/types/Video';

const MediaModuleSlideProps = Vue.extend({
    name: 'MediaModuleSlide',
    props: {
        template: {
            type: Object as PropType<Partial<VideoTemplate>>,
            default() {
                return {
                    width: 0,
                    height: 0,
                    video_boxes: [],
                    module_type: null
                };
            }
        },
        readonly: {
            type: Boolean,
            default() {
                return false;
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        },
        label: {
            type: String,
            default() {
                return '';
            }
        },
        field: {
            type: String,
            default() {
                return '';
            }
        },
        name: {
            type: String,
            default() {
                return '';
            }
        }
    }
});

@Component
export default class MediaModuleSlide extends mixins(
    MediaModuleSlideCollector,
    MediaModuleSlideProps,
    MediaModuleValidate,
    ResourceFile,
    MediaBoxActions
) {
    _uid!: number;

    $refs!: {
        provider: InstanceType<typeof ValidationProvider>;
        container: InstanceType<typeof HTMLDivElement | VueConstructor>;
    };

    scale = 0.1; // to begin loading with loader visible

    get id() {
        return this._uid;
    }

    get isLoading() {
        return this.loading || !this.boxes.length;
    }

    get boxes() {
        return this.template?.video_boxes || [];
    }

    get width() {
        return this.template?.width || 0;
    }

    get height() {
        return this.template?.height || 0;
    }

    get totalSlides() {
        return this.boxes[this.boxes.length - 1]?.ui_group + 1 || 0;
    }

    get slideStyle() {
        return {
            transform: `scale(${
                this.isLoading ? 0.1 : this.scale
            }) translate(-50%, -50%)`,
            width: `${this.width}px`,
            height: `${this.height}px`
        };
    }

    get rules() {
        return `slideshow:${this.id}`;
    }

    get fieldName() {
        return this.field || this.label || this.name;
    }

    get isInfographicModule() {
        return this.template.module_type === 7;
    }

    @Watch('template')
    onTemplateUpdate() {
        this.syncValue();
        this.computeScale();
    }

    created() {
        setValidator(this.id, () => this.validate(this.boxes));
    }

    beforeDestroy() {
        unsetValidator(this.id);
    }

    mounted() {
        // validation needs some initial value
        this.updateValue();
    }

    updateValue() {
        this.flipBoxes();
        this.$refs.provider.syncValue(JSON.stringify(this.boxes));
    }

    async change() {
        await this.syncValue();

        this.$refs.provider.setFlags({
            untouched: false,
            touched: true,
            dirty: true,
            changed: true,
            pristine: false
        });
    }

    onResize() {
        this.computeScale();
    }

    async syncValue() {
        this.updateValue();

        await this.$refs.provider.validate();
    }

    computeScale() {
        if (this.width && this.height) {
            const { width, height } = this.getContainerBoxRect();
            const scale = Math.min(width / this.width, height / this.height);

            // allow for smooth animation
            this.$nextTick(() => {
                this.scale = scale;
            });
        }
    }

    getContainerBoxRect() {
        if ('$el' in this.$refs.container) {
            return this.$refs.container.$el.getBoundingClientRect();
        }

        return this.$refs.container.getBoundingClientRect();
    }

    isNonOptimalOrientation(box: VideoBox) {
        if (!box.video_media_box || box.width === box.height) {
            return false;
        }

        const resourceFile = this.getResourceFile(box.video_media_box);

        if (!resourceFile?.width || !resourceFile?.height) {
            return false;
        }

        const isBoxLandscape = box.width > box.height;
        const isResourceLandscape = resourceFile.width > resourceFile.height;

        return isBoxLandscape !== isResourceLandscape;
    }

    getGroupsToFlip() {
        return this.boxes.reduce((acc: number[], box) => {
            if (
                box.video_media_box &&
                box.width !== box.height &&
                box.alternate_width
            ) {
                const resourceFile = this.getResourceFile(box.video_media_box);
                // if it's landscape and needs to be portrait, and has alternate dimensions
                if (resourceFile && this.isNonOptimalOrientation(box)) {
                    acc.push(box.ui_group);
                }
            }

            return acc;
        }, []);
    }

    hasAlternateDimension(box: VideoBox) {
        return (
            typeof box.alternate_width === 'number' &&
            typeof box.alternate_height === 'number' &&
            typeof box.alternate_x === 'number' &&
            typeof box.alternate_y === 'number'
        );
    }

    updateToAlternateDimension(box: VideoBox) {
        if (typeof box.alternate_x === 'number') {
            box.x = box.alternate_x;
        }

        if (typeof box.alternate_y === 'number') {
            box.y = box.alternate_y;
        }

        if (typeof box.alternate_width === 'number') {
            box.width = box.alternate_width;
        }

        if (typeof box.alternate_height === 'number') {
            box.height = box.alternate_height;
        }
    }

    updateToOriginalDimension(box: VideoBox) {
        box.x = box.original_x;
        box.y = box.original_y;
        box.width = box.original_width;
        box.height = box.original_height;
    }

    setOriginalDimension(box: VideoBox) {
        if (isNaN(box.original_width)) {
            box.original_x = box.x;
            box.original_y = box.y;
            box.original_width = box.width;
            box.original_height = box.height;
        }
    }

    isDifferentFromAlternateDimension(box: VideoBox) {
        return (
            box.width !== box.alternate_width ||
            box.x !== box.alternate_x ||
            box.y !== box.alternate_y ||
            box.height !== box.alternate_height
        );
    }

    flipIndividualNonOptimalOrientedBoxes() {
        this.boxes.forEach(box => {
            if (box.video_media_box) {
                const resourceFile = this.getResourceFile(box.video_media_box);
                // if it's landscape and needs to be portrait, and has alternate dimensions
                const isLandScape =
                    resourceFile && resourceFile.width > resourceFile.height;

                this.setOriginalDimension(box);

                if (this.hasAlternateDimension(box) && !isLandScape) {
                    this.updateToAlternateDimension(box);
                } else if (isLandScape) {
                    this.updateToOriginalDimension(box);
                }
            }
        });
    }

    flipGroupBoxesIfAnyIsNonOptimalOriented() {
        const groupsToFlip = this.getGroupsToFlip();

        if (groupsToFlip.length) {
            const groupBoxesToFlip = this.boxes.filter(
                box =>
                    groupsToFlip.includes(box.ui_group) &&
                    this.hasAlternateDimension(box)
            );

            groupBoxesToFlip.forEach(box => {
                this.setOriginalDimension(box);

                if (this.isDifferentFromAlternateDimension(box)) {
                    this.updateToAlternateDimension(box);
                } else {
                    this.updateToOriginalDimension(box);
                }
            });
        }
    }

    flipBoxes() {
        if (this.isInfographicModule) {
            // TODO: disable flipping of images on Infographic module
            // this.flipIndividualNonOptimalOrientedBoxes();
        } else {
            this.flipGroupBoxesIfAnyIsNonOptimalOriented();
        }
    }

    getZoomBoxForSlide(slide: number) {
        return this.boxes.find(
            box =>
                box.type === 'video_zoom_box' &&
                box.video_zoom_box &&
                box.ui_group === slide
        );
    }

    onMediaDeleted(deleted?: number[]) {
        if (deleted?.length) {
            this.boxes.forEach(box => {
                if (box.video_media_box) {
                    this.removeMediaFileIfDeleted(
                        deleted,
                        box.video_media_box.media_resource.media_file
                    );

                    if (box.video_media_box.media_files) {
                        box.video_media_box.media_files.forEach(file => {
                            this.removeMediaFileIfDeleted(deleted, file);
                        });
                    }
                }
            });
        }
    }

    removeMediaFileIfDeleted(deleted: number[], file?: MediaFile) {
        if (file && deleted.includes(file.id)) {
            this.removeMediaFile(file);
        }
    }

    removeMediaFile(file: MediaFile) {
        // TODO: We cannot nullify/remove the media_file for now
        // Instead, we reset it's dims so it doesn't appear on slides/scenes
        file.dimensions = null;
        file.thumbnail_dimensions = null;
    }

    slideAcceptsUserInput(slide: number) {
        const boxes = this.getAllBoxesForSlide(slide);

        return boxes.some(box => this.boxAcceptsUserInput(box));
    }

    boxAcceptsUserInput(box: VideoBox) {
        return box.video_media_box || box.video_text_box;
    }
}
