import { SchedulerType } from '@/types/ClientCabin';

import Calendly from '@/components/ClientCabin/Scheduler/Calendly';
import Acuity from '@/components/ClientCabin/Scheduler/Acuity';
import HubSpot from '@/components/ClientCabin/Scheduler/HubSpot';
import GoogleCalendar from '@/components/ClientCabin/Scheduler/GoogleCalendar';

export type SchedulerProvider = {
    id: SchedulerType;
    name: string;
    details?: string;
    helpLink: string;
    validator: (code: string) => Promise<boolean>;
};

const schedulerProviders: SchedulerProvider[] = [
    {
        id: 'calendly',
        name: 'Calendly',
        helpLink:
            'https://help.calendly.com/hc/en-us/articles/4409838727703-How-to-embed-and-customize-Calendly-on-your-website',
        validator: Calendly.validator
    },
    {
        id: 'acuity',
        name: 'Acuity',
        details:
            'We need a code provided for embedding into <b>Other websites</b>.',

        helpLink:
            'https://help.acuityscheduling.com/hc/en-us/articles/16676884389133-Adding-Acuity-Scheduling-to-your-website',
        validator: Acuity.validator
    },
    {
        id: 'hubspot',
        name: 'HubSpot',
        helpLink:
            'https://knowledge.hubspot.com/meetings-tool/embed-the-meeting-widget-on-a-page',
        validator: HubSpot.validator
    },
    {
        id: 'googlecalendar',
        name: 'Google Calendar',
        details:
            'Create your appointment schedule, then click <a href="https://support.google.com/calendar/answer/10733297" target="_blank">Share</a>, and select <b>Website embed</b>.',

        helpLink:
            'https://support.google.com/google-workspace-individual/answer/10729749',
        validator: GoogleCalendar.validator
    }
];

export default schedulerProviders;
