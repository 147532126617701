import { ClientCabinOptions } from '@/types/ClientCabin';

export { default as Setup } from './Setup.vue';
export { default as SetupButton } from './SetupButton.vue';

export const DefaultOptions: ClientCabinOptions = {
    custom_headline_short: '',
    custom_headline_long: '',
    video_url: '',
    sales_video_url: '',
    vip_buy_button_url: '',
    footer: {
        links: []
    },
    analytics: {
        google_analytics: '',
        fb_pixel: '',
        segment_io: ''
    },
    crm: {
        google: '',
        hubspot: '',
        getresponse: ''
    },
    default_crm: 'getresponse',
    scheduler: {
        calendly: '',
        hubspot: '',
        acuity: '',
        googlecalendar: ''
    },
    default_scheduler: '' // setting empty will use $user->reseller_booking_url
};
