<template>
    <div class="setup-button" :class="{ ready: canSetup }">
        <v-tooltip bottom>
            <template #activator="{ on, attrs }">
                <v-btn
                    class="white--text"
                    color="primary"
                    :small="$vuetify.breakpoint.smAndDown"
                    :disabled="!canSetup"
                    fab
                    absolute
                    top
                    right
                    v-bind="attrs"
                    v-on="on"
                    @click="openSetup"
                >
                    <v-icon small>gear</v-icon>
                </v-btn>
            </template>
            <span>Setup Your Invisible Sales Machine</span>
        </v-tooltip>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { InjectReactive, Watch } from '@/utils/decorators';

import type { ClientCabin } from '@/types/ClientCabin';

@Component
export default class SetupButton extends Vue {
    @InjectReactive({
        from: 'options',
        default() {
            return null;
        }
    })
    options!: Partial<ClientCabin> | null;

    @Watch('options')
    onOptionsUpdate() {
        this.$nextTick(() => {
            this.promptSetup();
        });
    }

    @Watch('$route')
    onRouterUpdate() {
        this.$nextTick(() => {
            this.promptSetup();
        });
    }

    get canSetup() {
        // options are still loading
        return this.options !== null;
    }

    get isResellerFunnelPage() {
        return ['/info', '/proposal', '/book', '/leads-from-ads'].includes(
            this.$route.path
        );
    }

    openSetup() {
        this.$store.dispatch('modal/open', 'clientcabin-setup-panel');
    }

    closeSetup() {
        this.$store.dispatch('modal/close', 'clientcabin-setup-panel');
    }

    promptSetup() {
        if (this.canSetup && this.isResellerFunnelPage) {
            const { options } = this.options || {};

            if (!options || this.$route.hash === '#setup') {
                if (this.$route.hash === '#setup') {
                    // remove the hash so we don't trigger setup opening again
                    this.$router.replace({ path: this.$route.path });
                }
                setTimeout(() => {
                    this.openSetup();
                }, 1000);
            }
        } else {
            this.closeSetup();
        }
    }
}
</script>
<style lang="scss" scoped>
.setup-button::v-deep {
    position: fixed;
    z-index: 5;
    top: 80px;
    right: 15px;

    &.ready > button {
        &:after {
            content: '';
            position: absolute;
            pointer-events: none;
            opacity: 0;
            width: 58px;
            height: 58px;
            background: rgba(254, 13, 102, 0.5);
            border-radius: 100%;
            transform: scale(1, 1) translate(-50%);
            transform-origin: 50% 50%;
            animation: ripple 5s ease-out infinite running;
        }
    }
}

@keyframes ripple {
    0% {
        transform: scale(0, 0);
        opacity: 1;
    }

    4% {
        transform: scale(1.2, 1.2);
        opacity: 1;
    }

    20%,
    100% {
        // adds 5 sec delay
        opacity: 0;
        transform: scale(2, 2);
    }
}
</style>
