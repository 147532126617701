<template>
    <v-expansion-panels accordion focusable flat tile>
        <v-expansion-panel>
            <v-expansion-panel-header class="font-weight-bold">
                Whitelabel Settings and Pricing
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <p class="pt-4">
                    Whitelabel settings and pricing can be changed in your
                    <a href="/app/profile/whitelabel" target="_blank">
                        Profile
                    </a>
                </p>
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
            <v-expansion-panel-header class="font-weight-bold">
                Footer links
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <p class="pt-4">Add custom links (up to 10) to footer</p>
                <div v-for="(link, index) in data.footer.links" :key="index">
                    <v-row dense>
                        <v-col
                            class="d-flex align-center text-body-1 font-weight-bold pb-0"
                        >
                            Link #{{ index + 1 }}
                        </v-col>
                        <v-col class="text-right pb-0">
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="removeLink(index)"
                                    >
                                        <v-icon small>times</v-icon>
                                    </v-btn>
                                </template>
                                Delete Link #{{ index + 1 }}
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" md="4">
                            <a-text-input
                                v-model="link.label"
                                label="Link Label"
                                rules="max:100"
                            />
                        </v-col>
                        <v-col cols="12" md="8">
                            <a-text-input
                                v-model="link.url"
                                label="Link URL"
                                rules="url|max:255"
                                autocomplete="off"
                            />
                        </v-col>
                    </v-row>
                </div>
                <v-btn primary :disabled="!canAddMoreLinks" @click="addLink">
                    Add link
                </v-btn>
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
            <v-expansion-panel-header class="font-weight-bold">
                Analytics
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <p class="pt-4">
                    Add your
                    <b>Google Tag</b>
                    Manager ID (GTM-XXXXXXXX), your
                    <b>Facebook Pixel</b>
                    ID (typically a 15-digit number), or your
                    <b>Segment.io</b>
                    project's write key
                </p>
                <a-text-input
                    v-model="data.analytics.google_analytics"
                    label="Google Tag Manager ID"
                    rules="alpha_dash"
                    hint="GTM-"
                />
                <a-text-input
                    v-model="data.analytics.fb_pixel"
                    label="Facebook Pixel ID"
                    rules="alpha_dash"
                    hint="Typically a 15-digit number"
                />
                <a-text-input
                    v-model="data.analytics.segment_io"
                    label="Segment.io Write Key"
                    rules="alpha_dash"
                />
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { Watch } from '@/utils/decorators';

import { ATextInput } from '@/components/AForm/Inputs/ATextInput';

import { DefaultOptions } from '@/components/ClientCabin/Setup';

import type { ClientCabinOptions } from '@/types/ClientCabin';

const GeneralSettingsProps = Vue.extend({
    name: 'GeneralSettings',
    props: {
        options: {
            type: Object as PropType<ClientCabinOptions>,
            default() {
                return DefaultOptions;
            }
        }
    }
});

@Component({
    name: 'Setup',
    components: {
        ATextInput
    }
})
export default class GeneralSettings extends GeneralSettingsProps {
    data = this.options;

    @Watch('data', { deep: true })
    onChaage() {
        this.$emit('input', this.data);
    }

    get canAddMoreLinks() {
        return this.data.footer.links.length < 10;
    }

    addLink() {
        this.data.footer.links.push({ label: '', url: '' });
    }

    removeLink(index: number) {
        this.data.footer.links.splice(index, 1);
    }
}
</script>
