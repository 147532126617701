<template>
    <v-dialog
        v-if="isRendered"
        v-model="isOpen"
        :max-width="maxWidth"
        :persistent="persistent"
        scrollable
    >
        <v-card class="modal-wrapper">
            <v-card-title class="modal-title">
                <v-row>
                    <v-col class="modal-title-column">{{ title }}</v-col>
                    <v-col v-if="closable" cols="auto">
                        <v-btn icon data-testid="close-button" @click="close">
                            <v-icon>times</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-card-subtitle v-if="subTitle">
                {{ subTitle }}
            </v-card-subtitle>

            <v-card-text class="modal-body">
                <slot></slot>
            </v-card-text>

            <v-card-actions
                v-if="$slots.actions"
                class="pb-4"
                :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
            >
                <slot name="actions"></slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

const ModalProps = Vue.extend({
    name: 'Modal',
    props: {
        id: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        subTitle: {
            type: String,
            required: false,
            default() {
                return '';
            }
        },
        persistent: {
            type: Boolean,
            default() {
                return true;
            }
        },
        closable: {
            type: Boolean,
            default() {
                return true;
            }
        },
        maxWidth: {
            type: String,
            default() {
                return '800px';
            }
        }
    }
});

@Component
export default class Modal extends ModalProps {
    wasOpen = false;

    get isOpen() {
        const isOpen = this.$store?.getters['modal/open'](this.id);

        if (isOpen && !this.wasOpen) {
            this.$emit('open');
        }

        if (this.wasOpen !== isOpen) {
            this.wasOpen = isOpen;
        }

        return isOpen;
    }

    set isOpen(isOpen: boolean) {
        if (!isOpen) {
            this.$store.dispatch('modal/close', this.id);
        }
    }

    get isRendered() {
        return this.$store?.getters['modal/rendered'](this.id);
    }

    close() {
        this.$emit('close');

        this.$store.dispatch('modal/close', this.id);
    }
}
</script>

<style lang="scss" scoped>
.modal-wrapper {
    &.v-card > .v-card__text {
        color: $secondary-color;
    }

    .modal-title {
        border-top: $primary-color 6px solid;

        .modal-title-column {
            word-break: break-word;
        }
    }
}
</style>
