import DOMPurify from 'dompurify';

type HubSpotParameters = {
    dataSrc: string | null;
};

export const extractor = (code: string) => {
    const extracted: HubSpotParameters = {
        dataSrc: null
    };

    const sanitized = DOMPurify.sanitize(code, {
        ADD_TAGS: ['div'],
        ADD_ATTR: ['data-src']
    });

    if (sanitized) {
        const parser = new DOMParser();

        const form = parser.parseFromString(sanitized, 'text/html');
        const div = form.querySelector('[data-src]');

        if (div) {
            const src = div.getAttribute('data-src') || '';

            const [, tail] = src.split('hubspot.com/');

            if (tail) {
                // avoid hacky links
                extracted.dataSrc = `https://meetings.hubspot.com/${tail}`;
            }
        }
    }

    return extracted;
};

export const validator = async (code: string) => {
    if (!code) {
        return false;
    }

    const { dataSrc } = extractor(code);

    return Boolean(dataSrc);
};

export default { validator, extractor };
